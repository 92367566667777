<template>
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col-lg-12">
                <div class="d-flex align-items-center">
                    <img src="stoke/assets/images/users/avatar-1.jpg" alt="" class="avatar-sm rounded" />
                    <div class="ms-3 flex-grow-1">
                        <h5 class="mb-2 card-title">Hello, {{ user.name }}</h5>
                        <p class="text-muted mb-0">Tổng quan</p>
                    </div>
                    <div>
                        <a href="javascript:void(0);" class="btn btn-primary"><i class="bx bx-plus align-middle"></i>
                            Add New Jobs</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-12">
                <div class="row mb-4">
                    <div class="col-12 fw-bold">
                        <h5 class="text-primary">Chọn thời gian thống kê</h5>
                    </div>
                    <div class="col-md-12">
                        <date-range-picker ref="picker" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                            v-model="dateRange" :autoApply="true" opens="right" :show-week-numbers="false"
                            class="issue__filter__time_picker w-100"
                            custom-range-label="Chọn ngày bắt đầu - kết thúc" :singleDatePicker="false"
                            :show-ranges="true" @update="getStatistics" />
                    </div>
                </div>
            </div>
        </div>

        <div v-show="jobFacebookStatistics && jobFacebookStatistics.total_quantity" class="row">
            <!--end col-->
            <div class="col-lg-3">
                <div class="card mini-stats-wid">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <p class="text-muted fw-medium">
                                    Tổng Jobs ({{ jobFacebookStatistics.total_quantity_origin | formatNumber }} Like)
                                    <i @click="isShowInfo = !isShowInfo" class="text-primary fa fa-info-circle"></i>
                                </p>
                                <p v-show="isShowInfo" class="text-info fw-medium">
                                    Trong đó: {{ jobFacebookStatistics.total_quantity_origin | formatNumber }} Like
                                    Khách Mua - {{ jobFacebookStatistics.total_quantity | formatNumber }} Like Chạy
                                    Thực (Tính cả số lượng like bù hoặc chạy dư để tránh tụt cho khách)
                                </p>
                                <h4 class="mb-0">{{ jobFacebookStatistics.total | formatNumber }}</h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                                <div data-colors='["--bs-success", "--bs-transparent"]' dir="ltr"
                                    id="eathereum_sparkline_charts"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body border-top py-3">
                        <p class="mb-0">
                            <span class="badge badge-soft-success me-1"><i
                                    class="bx bx-trending-up align-bottom me-1"></i>
                                {{ jobFacebookStatistics.total_price_jobs | formatNumber }} đ</span>
                            Tổng tiền khách mua
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
            <div class="col-lg-3">
                <div class="card mini-stats-wid">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <p class="text-muted fw-medium">
                                    Jobs đang chạy ({{ jobFacebookStatistics.total_pending_run_count | formatNumber }}
                                    Like)
                                </p>
                                <h4 class="mb-0">{{ jobFacebookStatistics.running | formatNumber }}</h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                                <div data-colors='["--bs-success", "--bs-transparent"]' dir="ltr"
                                    id="new_application_charts"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body border-top py-3">
                        <p class="mb-0">
                            <span class="badge badge-soft-success me-1"><i
                                    class="bx bx-trending-up align-bottom me-1"></i>
                                {{ jobFacebookStatistics.total_price_jobs_running | formatNumber }} đ</span>
                            Tổng tiền chưa chạy
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
            <div class="col-lg-3">
                <div class="card mini-stats-wid">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <p class="text-muted fw-medium">
                                    Jobs Hoàn thành ({{
                            jobFacebookStatistics.total_quantity_jobs_success | formatNumber
                        }}
                                    Like)
                                </p>
                                <h4 class="mb-0">{{ jobFacebookStatistics.success | formatNumber }}</h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                                <div data-colors='["--bs-success", "--bs-transparent"]' dir="ltr"
                                    id="total_approved_charts"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body border-top py-3">
                        <p class="mb-0">
                            <span class="badge badge-soft-success me-1"><i
                                    class="bx bx-trending-up align-bottom me-1"></i>
                                {{ jobFacebookStatistics.total_price_jobs_success | formatNumber }} đ</span>
                            Tổng tiền hoàn thành
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
            <div class="col-lg-3">
                <div class="card mini-stats-wid">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <p class="text-muted fw-medium">
                                    Jobs Bị Ẩn ({{ jobFacebookStatistics.total_quantity_jobs_hidden | formatNumber }}
                                    Like)
                                </p>
                                <h4 class="mb-0">{{ jobFacebookStatistics.hidden | formatNumber }}</h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                                <div data-colors='["--bs-danger", "--bs-transparent"]' dir="ltr"
                                    id="total_rejected_charts"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body border-top py-3">
                        <p class="mb-0">
                            <span class="badge badge-soft-danger me-1"><i
                                    class="bx bx-trending-down align-bottom me-1"></i>
                                {{ jobFacebookStatistics.total_price_jobs_hidden | formatNumber }} đ</span>
                            Tổng tiền bị ẩn
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- statistics coin -->
        <div v-show="jobFacebookStatistics && jobFacebookStatistics.farmer && jobFacebookStatistics.farmer.coin
                            " class="row">
            <!-- tổng khách mua -->
            <div class="col-lg-3">
                <div class="card mini-stats-wid">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <p class="text-muted fw-medium">Tổng tiền Farmer đã làm</p>
                                <h4 class="mb-0">{{ jobFacebookStatistics.farmer.coin | formatNumber }} đ</h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                                <div data-colors='["--bs-danger", "--bs-transparent"]' dir="ltr"
                                    id="total_rejected_charts"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <!-- Tổng tiền đã rút -->
            <div class="col-lg-3">
                <div class="card mini-stats-wid">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <p class="text-muted fw-medium">Tổng tiên đã rút</p>
                                <h4 class="mb-0">{{ coinStatistics.total_withdraw | formatNumber }} đ</h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                                <div data-colors='["--bs-danger", "--bs-transparent"]' dir="ltr"
                                    id="total_rejected_charts"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <!-- Tổng số dư chưa rút -->
            <div class="col-lg-3">
                <div class="card mini-stats-wid">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <p class="text-muted fw-medium">Tổng số dư Farmer</p>
                                <h4 class="mb-0">{{ coinStatistics.total_coin | formatNumber }} đ</h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                                <div data-colors='["--bs-success", "--bs-transparent"]' dir="ltr"
                                    id="eathereum_sparkline_charts"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <!-- Tổng lợi nhuận -->
            <div class="col-lg-3">
                <div class="card mini-stats-wid">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <p class="text-muted fw-medium">Tổng lợi nhuận dự tính</p>
                                <h4 class="mb-0">
                                    {{ jobFacebookStatistics.revenues.origin.coin | formatNumber }} đ
                                </h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                                <div data-colors='["--bs-success", "--bs-transparent"]' dir="ltr"
                                    id="eathereum_sparkline_charts"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3">
                <div class="card mini-stats-wid">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <p class="text-muted fw-medium">Tổng chờ duyệt</p>
                                <h4 class="mb-0">{{ coinStatistics.pending | formatNumber }} đ</h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                                <div data-colors='["--bs-success", "--bs-transparent"]' dir="ltr"
                                    id="new_application_charts"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-3">
                <div class="card mini-stats-wid">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <p class="text-muted fw-medium">Tổng tiên lỗi</p>
                                <h4 class="mb-0">{{ coinStatistics.errors | formatNumber }} đ</h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                                <div data-colors='["--bs-success", "--bs-transparent"]' dir="ltr"
                                    id="total_approved_charts"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
            <div class="col-lg-3">
                <div class="card mini-stats-wid">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <p class="text-muted fw-medium">Tổng tiên đã duyệt (kể cả đã rút)</p>
                                <h4 class="mb-0">{{ coinStatistics.done | formatNumber }} đ</h4>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                                <div data-colors='["--bs-danger", "--bs-transparent"]' dir="ltr"
                                    id="total_rejected_charts"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4">Top Farmer</h4>
                        <div class="table-responsive">
                            <table class="table align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th class="align-middle">Ngày</th>
                                        <th class="align-middle">User</th>
                                        <th class="align-middle">Ref</th>
                                        <th class="align-middle">Name</th>
                                        <th class="align-middle">Email</th>
                                        <th class="align-middle">Coin</th>
                                        <th class="align-middle">Tổng tiền</th>
                                        <th class="align-middle">Job Hoàn thành</th>
                                        <th class="align-middle">Job Die</th>
                                        <th class="align-middle">Job Skip</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(statistic, index) in statisticsByDate" :key="index">
                                        <td>
                                            <a href="javascript: void(0);" class="text-body fw-bold">{{
                            statistic.date
                        }}</a>
                                        </td>
                                        <td>{{ statistic.username }}</td>
                                        <td>{{ statistic.user.referral }}</td>
                                        <td>{{ statistic.user.name }}</td>
                                        <td>{{ statistic.user.email }}</td>
                                        <td>{{ statistic.user.coin | formatNumber }}</td>
                                        <td>{{ statistic.total_coin | formatNumber }}</td>
                                        <td>{{ statistic.total_complete | formatNumber }}</td>
                                        <td>{{ statistic.total_die | formatNumber }}</td>
                                        <td>{{ statistic.total_skip | formatNumber }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- end table-responsive -->
                    </div>
                </div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!-- container-fluid -->
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import _ from "lodash";
import statisticsApi from "@/api/statistics";
import moment from "moment";

export default {
    name: "Home",
    components: {
        DateRangePicker,
    },
    data() {
        return {
            isShowInfo: false,
            jobFacebookStatistics: {
                farmer: { coin: 0 },
                revenues: {
                    origin: {
                        coin: 0,
                    },
                },
            },
            coinStatistics: {},
            statisticsByDate: [],
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
        };
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
        isManager() {
            return this.user.role && this.user.role.length > 1 && this.user.role !== "user";
        },
    },
    created() {
        this.getStatistics();
    },
    methods: {
        async getStatistics() {
            this.fetchStatisticsByDate();
            this.fetchStatisticsJobs();
            this.fetchStatisticsCoin();
        },
        async fetchStatisticsCoin() {
            const { startDate, endDate } = this.dateRange;
            console.log(moment(startDate).format("YYYY-MM-DD"));
            const response = await statisticsApi.coin(
                `?date_from=${moment(startDate).format("YYYY-MM-DD")}&date_to=${moment(endDate).format(
                    "YYYY-MM-DD"
                )}`
            );
            if (response.data) {
                this.coinStatistics = response.data;
                console.log(this.coinStatistics);

                this.coinStatistics.profit =
                    this.coinStatistics.total_buff - this.coinStatistics.done - this.coinStatistics.pending;
            }
        },
        async fetchStatisticsJobs() {
            const { startDate, endDate } = this.dateRange;
            console.log(moment(startDate).format("YYYY-MM-DD"));
            const response = await statisticsApi.jobs(
                `?start_date=${moment(startDate).format("YYYY-MM-DD")}&end_date=${moment(endDate).format(
                    "YYYY-MM-DD"
                )}`
            );
            if (response.data) {
                this.jobFacebookStatistics = response.data.facebook;
                console.log(this.jobFacebookStatistics);
            }
        },
        async fetchStatisticsByDate() {
            const { startDate, endDate } = this.dateRange;
            const response = await statisticsApi.listStatisticsByDate(
                `?date_from=${moment(startDate).format("YYYY-MM-DD")}&date_to=${moment(endDate).format(
                    "YYYY-MM-DD"
                )}`
            );
            if (response.data) {
                this.statisticsByDate = response.data;
                console.log(this.statisticsByDate);
            }
        },
    },
};
</script>

<style scoped></style>
